import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { VerticalSeparator, HorizontalSeparator } from '../separator';
import { TextIcon } from '../icons/text-icon';
import SendIcon from '../icons/send-icon';
import { CloseCircleIcon } from '../icons/close-circle-icon';
import { GatewayDest } from 'react-gateway';
import ProtectedButton from '../../containers/protected-button';
import styles from './rich-content-editor-footer.scss';

const PluginToolbar = () => (
  <div className={styles.pluginToolbar}>
    <GatewayDest key="external-plugin-toolbar" name="external-plugin-toolbar" />
  </div>
);

const FormattingToolbar = () => (
  <React.Fragment>
    <div className={styles.gradientOverlayLeft} />
    <div className={styles.formattingToolbar}>
      <GatewayDest key="external-formatting-toolbar" name="external-formatting-toolbar" />
    </div>
    <div className={styles.gradientOverlayRight} />
  </React.Fragment>
);

class RichContentEditorFooter extends React.Component {
  state = { isPluginToolbarActive: true };

  handleSwitchToolbars = e => {
    e.preventDefault();
    this.setState({ isPluginToolbarActive: !this.state.isPluginToolbarActive });
  };

  render() {
    const { isPluginToolbarActive } = this.state;
    const { onSubmit, isDisabled, savedAction, submitIcon: SubmitIcon } = this.props;
    const Icon = SubmitIcon ? SubmitIcon : SendIcon;

    return (
      <div className={classNames(styles.container, 'forum-card-background-color')}>
        <HorizontalSeparator />
        <div className={styles.controls}>
          <div className={classNames(styles.switchContainer, 'forum-icon-fill')}>
            <button
              className={classNames(styles.switchButton)}
              onMouseDown={this.handleSwitchToolbars}
            >
              {isPluginToolbarActive ? <TextIcon /> : <CloseCircleIcon />}
            </button>
            <VerticalSeparator className={styles.separator} />
          </div>
          <div className={styles.toolbarContainer}>
            {isPluginToolbarActive ? <PluginToolbar /> : <FormattingToolbar />}
          </div>
          {!isPluginToolbarActive && <VerticalSeparator className={styles.separator} />}
          {onSubmit && (
            <ProtectedButton
              className={styles.submitButton}
              onClick={onSubmit}
              actionDetails={savedAction}
              isDisabled={isDisabled}
              data-hook="submit-button"
            >
              <Icon
                className={
                  isDisabled ? classNames('forum-icon-fill', styles.disabled) : 'button-fill'
                }
              />
            </ProtectedButton>
          )}
        </div>
      </div>
    );
  }
}

RichContentEditorFooter.propTypes = {
  onSubmit: PropTypes.func,
  savedAction: PropTypes.object,
  isDisabled: PropTypes.bool,
  submitIcon: PropTypes.node,
};

export default RichContentEditorFooter;
