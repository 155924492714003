import React from 'react';
import PropTypes from 'prop-types';
import ToHTML from '../../../common/components/to-html/to-html';
import withTranslate from '../../../common/components/with-translate/with-translate';

export const FollowNotification = ({ isFollowing, t }) => {
  return <ToHTML html={t(isFollowing ? 'category-actions.unfollow' : 'category-actions.follow')} />;
};

FollowNotification.propTypes = {
  className: PropTypes.string,
  isFollowing: PropTypes.bool,
  isBell: PropTypes.bool,
};

export default withTranslate(FollowNotification);
