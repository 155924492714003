import React from 'react';

export const VoteDownIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13" viewBox="0 0 24 13" {...props}>
    <path
      fillRule="evenodd"
      d="M12.735 12.204L22.45 1.678A1 1 0 0 0 21.716 0H2.284a1 1 0 0 0-.735 1.678l9.716 10.526a1 1 0 0 0 1.47 0z"
    />
  </svg>
);

export const VoteDownIconMobile = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M12.573 4.925c.096.067.18.15.246.245l5.08 7.257c.316.452.206 1.076-.246 1.392-.168.118-.368.181-.574.181H15L15 19c0 .552-.448 1-1 1h-4c-.552 0-1-.448-1-1v-5H6.92c-.552 0-1-.448-1-1 0-.205.064-.405.181-.573l5.08-7.257c.316-.452.94-.562 1.392-.245z"
      transform="rotate(180 12 12.5)"
    />
  </svg>
);
