import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { LockSlimIcon } from '../icons/lock-slim-icon';
import { CommentDisabledIcon } from '../icons/comment-disabled-icon';
import withFontClassName from '../../hoc/with-font-class-name';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDividerColor from '../../hoc/with-divider-color';
import withDeviceType from '../../hoc/with-device-type';
import styles from './commenting-disabled.scss';

const CommentingDisabled = ({
  message,
  contentFontClassName,
  borderWidth,
  dividerColor,
  isInPostMainAction,
  isMobile,
}) => {
  const containerClassName = classNames(
    styles.container,
    isInPostMainAction && styles.postMainActionContainer,
    contentFontClassName,
    'forum-text-color',
  );
  return (
    <div
      className={containerClassName}
      style={{ borderWidth, borderColor: dividerColor }}
      data-hook="disabled-commenting"
    >
      <span
        className={classNames(
          styles.wrapper,
          isInPostMainAction && styles.postMainActionWrapper,
          styles.postPageRevamp,
        )}
      >
        {isMobile ? (
          <CommentDisabledIcon className={classNames('forum-icon-fill', styles.icon)} />
        ) : (
          <LockSlimIcon className={classNames('forum-icon-fill', styles.icon)} />
        )}
        {message}
      </span>
    </div>
  );
};

CommentingDisabled.propTypes = {
  message: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  isInPostMainAction: PropTypes.bool,
  borderWidth: PropTypes.number.isRequired,
};

export default flowRight(
  withFontClassName,
  withCardBorderWidth,
  withDividerColor,
  withDeviceType,
)(CommentingDisabled);
