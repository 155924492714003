import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import TypingListLoader from '../typing-list-loader';
import { HorizontalSeparator } from '../separator';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './typing-list.scss';

const TypingList = ({ users = [], t }) => {
  if (users.length) {
    return (
      <div
        className={classNames(
          styles.typing,
          'typing-list',
          'forum-card-border-color',
          'forum-text-color',
          'forum-card-background-color',
        )}
        data-hook="typing-indicator"
      >
        <div className={classNames(styles.background, 'forum-card-background-color')} />
        <div className={styles.content}>
          <HorizontalSeparator className={styles.separator} />
          <div className={styles.wrapper}>
            {t('typing-list.is-typing', { count: users.length, member: users[0].name })}
            <TypingListLoader />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

TypingList.propTypes = {
  users: PropTypes.array,
  t: PropTypes.func,
};

export default withTranslate(TypingList);
