import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { FacebookIcon } from '../icons/facebook-icon';
import { TwitterIcon } from '../icons/twitter-icon';
import ShareIcon from '../icons/share-icon';
import styles from './post-share-button.scss';
import { withPreviewClickBlocker } from '../../../common/components/click-blocker/with-preview-click-blocker';

const ICONS = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  link: ShareIcon,
};

class PostShareButton extends Component {
  handleClick = () => {
    const { onClick, type } = this.props;
    onClick(type);
  };

  render = () => {
    const { type, title, contentFontClassName, text, preventClickInPreview } = this.props;
    const iconClassName = classNames(
      styles.icon,
      'forum-text-color',
      'button-hover-color',
      contentFontClassName,
      {
        'button-hover-fill': type === 'link',
      },
    );
    const Icon = ICONS[type];

    return (
      <button
        className={classNames(styles.button, styles[type], iconClassName)}
        onClick={preventClickInPreview(this.handleClick)}
        aria-label={title}
      >
        <Icon />
        {text && <span className={styles.share}>{text}</span>}
      </button>
    );
  };
}

PostShareButton.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  contentFontClassName: PropTypes.func.isRequired,
  preventClickInPreview: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default withPreviewClickBlocker(PostShareButton);
