import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './like-button.scss';

class LikeButton extends Component {
  state = {
    isAnimationEnabled: false,
  };

  constructor(props) {
    super(props);

    this.handleAnimation = this.handleAnimation.bind(this);
  }

  handleAnimation(event) {
    if (this.props.isDisabled) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (!this.props.isLiked) {
      this.setState({ isAnimationEnabled: true });
    }
  }

  render() {
    const { isLiked, likeCount, useThemeColor, t } = this.props;
    const { isAnimationEnabled } = this.state;
    const likedStatusLabel = isLiked ? t('like-button.is-liked') : t('like-button.is-unliked');

    return (
      <div
        onClick={this.handleAnimation}
        className={classNames(
          styles.heart,
          { [styles.active]: isLiked },
          { [styles.isAnimationEnabled]: isAnimationEnabled },
        )}
        aria-label={`${t('like-button.total-likes', { count: likeCount })}. ${likedStatusLabel}`}
        data-hook="like-button"
        style={
          useThemeColor
            ? {}
            : {
                '--heart-color': '#e84a43',
              }
        }
      >
        <div className={classNames(styles.pos1)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.pos2)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.pos3)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.pos4)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.heartRing)} />
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M16 5.5c-1.137 0-2.176.422-2.968 1.118-.321.324-.533.55-.632.682-.087.116-.22.334-.4.655-.18-.32-.313-.54-.4-.655-.098-.131-.31-.357-.63-.68C10.179 5.923 9.139 5.5 8 5.5c-1.243 0-2.368.504-3.182 1.318C4.004 7.632 3.5 8.758 3.5 10c0 1.48.714 2.792 1.813 3.614L12 19.342l6.684-5.73C19.786 12.792 20.5 11.48 20.5 10c0-1.243-.504-2.368-1.318-3.182C18.368 6.004 17.242 5.5 16 5.5z" />
        </svg>
      </div>
    );
  }
}

LikeButton.propTypes = {
  isLiked: PropTypes.bool,
  likeCount: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  t: PropTypes.func.isRequired,
  useThemeColor: PropTypes.bool,
};

export default withTranslate(LikeButton);
