import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { XSmall } from '../icons/x-small';
import styles from './who-liked-list-header-mobile.scss';

export const MOBILE_HEADER_HEIGHT = 64;

class WhoLikedListHeaderMobile extends Component {
  render() {
    const { t, contentFontClassName, totalItemCount, onClose } = this.props;
    return (
      <div className={styles.container}>
        <h3
          className={classNames(
            styles.header,
            contentFontClassName,
            'default-desktop-header-text-color',
          )}
        >
          {t('who-liked.header-title', { likes: totalItemCount })}
        </h3>
        <XSmall
          className={classNames(styles.closeIcon, 'default-main-icon-fill')}
          onClick={() => onClose()}
          data-hook="modal-close"
        />
      </div>
    );
  }
}

WhoLikedListHeaderMobile.propTypes = {
  totalItemCount: PropTypes.number,
  contentFontClassName: PropTypes.number,
};

export default flowRight(withFontClassName, withTranslate)(WhoLikedListHeaderMobile);
