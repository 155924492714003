import PropTypes from 'prop-types';
import React from 'react';
import MobileCtaButton from '../mobile-cta-button';
import { NotificationIcon } from '../icons/notification-icon';
import styles from './follow-card.scss';
import FollowCategoryHeadless from '../follow-category-headless';
import { NotificationFollowingIcon } from '../icons/notification-following-icon';
import ensureAuth from '../../hoc/ensure-auth';

const MobileCtaButtonWithAuth = ensureAuth(MobileCtaButton);

const FollowCard = ({ category, withoutPaddingTop }) => {
  const getLabel = isSubscribed => {
    if (category._id) {
      return isSubscribed
        ? 'category-actions.unsubscribe-category'
        : 'category-actions.subscribe-category';
    } else {
      return isSubscribed
        ? 'category-actions.unsubscribe-all-categories'
        : 'category-actions.subscribe-all-categories';
    }
  };

  return (
    <FollowCategoryHeadless
      category={category}
      shouldSubscribeToAll={!category._id}
      data-hook="post-list-item__follow-button"
    >
      {({ onClick, isSubscribed }) => (
        <MobileCtaButtonWithAuth
          Icon={isSubscribed ? NotificationFollowingIcon : NotificationIcon}
          label={getLabel(isSubscribed)}
          dataHook="follow-category-cta"
          containerClassNames={withoutPaddingTop && styles.buttonContainer}
          isSecondary
          onClick={onClick}
        />
      )}
    </FollowCategoryHeadless>
  );
};

FollowCard.propTypes = {
  category: PropTypes.object,
  withoutPaddingTop: PropTypes.bool,
};

export default FollowCard;
