import React from 'react';

export const CloseCircleIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm4.03 4.97c-.293-.293-.767-.293-1.06 0L12 10.939l-2.97-2.97-.084-.072-.102-.064c-.282-.145-.638-.1-.874.137-.293.293-.293.767 0 1.06L10.939 12l-2.97 2.97-.072.084c-.218.293-.194.71.073.976.293.293.767.293 1.06 0L12 13.061l2.97 2.97.084.072c.293.218.71.194.976-.073.293-.293.293-.767 0-1.06L13.061 12l2.97-2.97.072-.084c.218-.293.194-.71-.073-.976z"
    />
  </svg>
);
