import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import Link from '../link/internal-link';
import { getForumTitle } from '../../selectors/forum-data-selectors';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './header-title.scss';

export const HeaderTitle = ({ title, titleOverride, t, to }) => {
  const Component = to === undefined ? 'span' : Link;
  return (
    <div className={styles.title}>
      <Component to={to}>
        {titleOverride ? t(titleOverride) : title || t('header-title.default-title')}
      </Component>
    </div>
  );
};

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleOverride: PropTypes.string,
  t: PropTypes.func.isRequired,
  to: PropTypes.string,
};

const mapRuntimeToProps = state => ({
  title: getForumTitle(state),
});

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.FORUM_DATA]),
  withTranslate,
)(HeaderTitle);
