import withTranslate from '../with-translate/with-translate';
import { flowRight } from 'lodash';
import React, { Component } from 'react';
import withDeviceType from '../../../app/hoc/with-device-type';
import { isEditor, isPreview } from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';
import { PreviewClickBlockerContext } from './preview-click-blocker-context';
import styles from './preview-click-blocker.scss';

class PreviewClickBlocker extends Component {
  state = { position: null };
  constructor(props) {
    super(props);

    this.floaterRef = React.createRef();
    typeof document !== 'undefined' && document.addEventListener('scroll', this.hideFloater);
  }

  componentWillUnmount() {
    clearTimeout(this.hideFloaterTimeout);
    typeof document !== 'undefined' && document.removeEventListener('scroll', this.hideFloater);
  }

  decorate = cb => {
    if (!this.props.isPreview && !this.props.isEditor) {
      return cb;
    } else {
      return this.handleClick;
    }
  };

  handleClick = ev => {
    clearTimeout(this.hideFloaterTimeout);
    const x = ev.clientX;
    const y = ev.clientY;
    if (x && y) {
      const fRect = this.floaterRef.current.getBoundingClientRect();
      const fWidth = fRect.width;
      const adjustInIframe = Math.min(window.innerWidth - (x + fWidth / 2 + 10), 0); // so it doesn't go outside iframe
      const xAdjusted = x - fWidth / 2 + adjustInIframe;
      this.setState({
        position: {
          top: y + 20,
          left: Math.max(xAdjusted, 10),
        },
      });

      this.hideFloaterTimeout = setTimeout(this.hideFloater, 3000);
    }
  };

  hideFloater = () => this.setState({ position: null });

  render() {
    return (
      <PreviewClickBlockerContext.Provider value={this.decorate}>
        <React.Fragment>
          <div
            ref={this.floaterRef}
            className={styles.floatingText}
            style={{
              position: 'fixed',
              visibility: this.state.position ? 'visible' : 'hidden',
              ...this.state.position,
            }}
          >
            {this.props.t('preview-blocker.popup')}
          </div>
          {this.props.children}
        </React.Fragment>
      </PreviewClickBlockerContext.Provider>
    );
  }
}

const mapRuntimeToProps = state => {
  return {
    isEditor: isEditor(state),
    isPreview: isPreview(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withDeviceType,
  withTranslate,
)(PreviewClickBlocker);
