import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import EmptyStates from '../../components/empty-states';
import Page from '../../components/page';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDeviceType from '../../hoc/with-device-type';
import styles from './search-page.scss';

const SearchPage = ({ borderWidth, isMobile, t }) => {
  return (
    <Page noSpacing={isMobile}>
      <div style={{ marginTop: -borderWidth }}>
        <EmptyStates
          className={styles.emptyStatesContainer}
          title={t('search-page.no-results-title')}
          content={t('search-page.no-results-content')}
        />
      </div>
    </Page>
  );
};

SearchPage.propTypes = {
  t: PropTypes.func.isRequired,
  borderWidth: PropTypes.number,
  isMobile: PropTypes.bool,
};

export default flowRight(withDeviceType, withCardBorderWidth, withTranslate)(SearchPage);
